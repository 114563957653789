import { Form, Link, useNavigation } from "@remix-run/react";
import { Loader2, X } from "lucide-react";
import React from "react";
import { Button } from "~/components/button";
import * as toast from "~/components/toast";
import { useCurrentUser } from "~/hooks/use-current-user";
import { DragMode, useFileUploads } from "~/hooks/use-file-uploads";
import type { Mention, Tag } from "~/types";
import { cn } from "~/util/css";
import {
  hashtagOnCursor,
  mentionOnCursor,
  stripMentionsFormatter,
  stripTagsAndMentions,
} from "~/util/parse-tags";
import {
  showVidControlsRegex,
  videoUrlRegex,
  xVideoUrlRegex,
} from "~/util/sanitize";
import { getXVideosUrl } from "~/util/xvideo";
import TagInput from "./tag-input";
import { VideoPlayer } from "./video-player";

export function CreatePost() {
  const [currentUser] = useCurrentUser();
  const [text, setText] = React.useState("");
  const [videoLinks, setVideoLinks] = React.useState<Set<string>>(new Set());
  const [tags, setTags] = React.useState<Tag[]>([]);
  const [mentions, setMentions] = React.useState<Mention[]>([]);
  const [filteredTags, setFilteredTags] = React.useState<Tag[]>([]);
  const [currentTagQuery, setCurrentTagQuery] = React.useState<string>("");

  const onTagInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ref: HTMLTextAreaElement | HTMLInputElement,
  ) => {
    const value = e.target.value;

    if (value.length > 160) {
      setErrorMessages(["Posts cannot be longer than 160 characters"]);
      return;
    }

    const cursorPosition = ref.selectionStart ?? -1;
    const hashtagQuery = hashtagOnCursor(
      stripTagsAndMentions(value),
      cursorPosition,
    );
    const mentionQuery = mentionOnCursor(
      stripTagsAndMentions(value),
      cursorPosition,
    );

    if (hashtagQuery && hashtagQuery.length >= 2)
      setCurrentTagQuery(hashtagQuery);
    else setCurrentTagQuery("");

    if (mentionQuery && mentionQuery.length >= 2) {
      try {
        fetch(`/api/mentions/search?query=${mentionQuery}`)
          .then((res) => res.json())
          .then((data) => setMentions(data.mentions));
      } catch (err) {
        console.error("Error fetching mentions:", err);
      }
    } else {
      setMentions([]);
    }

    setErrorMessages([]);
    setText(stripMentionsFormatter(value));
  };

  React.useEffect(() => {
    fetch("/api/tags?count=1000")
      .then((res) => res.json())
      .then((data) => setTags(data.tags))
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    if (!currentTagQuery || currentTagQuery?.length <= 1) {
      setFilteredTags([]);
      return;
    }
    const regex = new RegExp(currentTagQuery, "i");
    const filtered = tags
      .filter((tag: Tag) => regex.test(tag.name))
      .slice(0, 10);
    setFilteredTags(filtered);
  }, [currentTagQuery]);

  const {
    uploads,
    isUploading,
    errorMessages,
    dragMode,
    setDragMode,
    setErrorMessages,
    processFile,
    validateFiles,
    setUploads,
    removeUpload,
  } = useFileUploads();

  const canPost = React.useMemo(() => {
    return currentUser && !isUploading && errorMessages.length === 0;
  }, [currentUser, isUploading, errorMessages]);

  const handlePaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!currentUser) {
      toast.fail({
        text: "You must be logged in to post",
        id: "login",
      });
      return;
    }

    const files = event.clipboardData?.files;
    const errors = validateFiles(files);

    if (errors && errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    for (const file of files) {
      await processFile(file);
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!currentUser) {
      toast.fail({
        text: "You must be logged in to post",
        id: "login",
      });
      return;
    }

    const files = event.dataTransfer.files;
    const errors = validateFiles(files);

    if (errors && errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    for (const file of files) {
      await processFile(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragMode(DragMode.Dragging);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragMode(DragMode.None);
  };

  const handleDropNoop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        await processFile(files[i]);
      }
    }
  };

  const navigation = useNavigation();
  const isPosting = navigation.state === "submitting";
  const formRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    formRef.current?.reset();
    setUploads([]);
    setDragMode(DragMode.None);
  }, [isPosting]);

  React.useEffect(() => {
    const mediaUrls = text.match(new RegExp(videoUrlRegex, "g")) || [];
    const xVideoMatches = text.match(new RegExp(xVideoUrlRegex, "g")) || [];

    const fetchVideos = async () => {
      const fetchedXVideoUrls = await Promise.all(
        xVideoMatches.map(async (url) => {
          const videoIdMatch = url.match(xVideoUrlRegex);
          if (!videoIdMatch) return null;
          const res = await getXVideosUrl(videoIdMatch[1]);
          if (res.error) toast.fail({ text: res.error, id: "error" });
          return res.src || null;
        }),
      );

      const xVideoUrls = fetchedXVideoUrls.filter(
        (url): url is string => url !== null,
      );

      setVideoLinks(
        (prevLinks) => new Set([...prevLinks, ...mediaUrls, ...xVideoUrls]),
      );
    };

    fetchVideos();
  }, [text]);

  return (
    <Form ref={formRef} method="post">
      <div
        onPaste={handlePaste}
        onDrop={isUploading ? handleDropNoop : handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={cn(
          "border border-primary rounded-xl px-4 py-2 flex gap-2 bg-white/60",
          {
            "border-blue-500": dragMode === DragMode.Dragging,
            "border-purple-200": isPosting,
          },
        )}
      >
        <div className="h-8">
          {currentUser ? (
            <Link to={`/${currentUser.username}`}>
              <div
                style={{
                  backgroundImage: `url(${
                    currentUser.avatarUrl || "/default-avatar.svg"
                  })`,
                  backgroundSize: "cover",
                }}
                className="rounded-full h-5 w-5 border border-gray-900 bg-center"
              />
            </Link>
          ) : (
            <div
              style={{
                backgroundImage: "url(/default-avatar.svg)",
                backgroundSize: "cover",
              }}
              className="rounded-full h-5 w-5 bg-primary border border-primary"
            />
          )}
        </div>
        <div className="w-full">
          {currentUser ? (
            <>
              <TagInput
                value={text}
                onChange={onTagInputChange}
                tags={filteredTags}
                mentions={mentions}
                placeholder={`What's poppin' ${currentUser.username ?? ""}?`}
              />
              <input type="hidden" name="content" value={text} />
            </>
          ) : (
            <button className="bg-transparent text-left w-full text-black/50">
              What's poppin?
            </button>
          )}

          {errorMessages.map((errorMessage) => {
            return (
              <div key={errorMessage} className="bg-red-200 rounded-xl p-2">
                {errorMessage}
              </div>
            );
          })}

          {/* Twitch/Youtube Link Parser */}
          {videoLinks &&
            [...videoLinks].map((link, index) => (
              <div
                key={index}
                className={cn(
                  "grid gap-2 bg-transparent w-full mb-2 min-h-[320px] max-h-[600px] overflow-x-hidden overflow-y-auto pt-2 pr-2",
                )}
              >
                <div className="w-full text-pink-500 relative">
                  <VideoPlayer
                    url={link}
                    showControls={showVidControlsRegex.test(link)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const updatedLinks = new Set([...videoLinks]);
                      updatedLinks.delete(link);
                      setVideoLinks(updatedLinks);
                    }}
                    className="absolute -top-[6px] -right-[6px] bg-pink-300 rounded-full border-2 border-black w-6 h-6 flex items-center justify-center text-black"
                  >
                    <X size={16} />
                  </button>
                  <input type="hidden" name="media" value={link} />
                  <input type="hidden" name="type" value="video" />
                </div>
              </div>
            ))}

          {uploads.length > 0 && (
            <div
              className={cn(
                "grid gap-2 bg-transparent w-full mb-2 min-h-[320px] max-h-[600px] overflow-x-hidden overflow-y-auto pt-2 pr-2",
                {
                  "grid-cols-1": uploads.length === 1,
                  "grid-cols-2": uploads.length > 1,
                },
              )}
            >
              {uploads.map((upload, index) => {
                return upload.blobUrl.startsWith("data:image/") ? (
                  <div
                    key={upload.name}
                    style={{
                      backgroundImage: `url(${upload.blobUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="w-full text-pink-500 relative"
                  >
                    {upload.isUploading && <ProcessingIndicator />}
                    {!upload.isUploading && (
                      <button
                        type="button"
                        onClick={() => removeUpload(index)}
                        className="absolute -top-[6px] -right-[6px] bg-pink-300 rounded-full border-2 border-black w-6 h-6 flex items-center justify-center text-black"
                      >
                        <X size={16} />
                      </button>
                    )}
                    <input
                      type="hidden"
                      name="media"
                      value={upload.uploadedUrl}
                    />
                    <input type="hidden" name="type" value="image" />
                  </div>
                ) : (
                  <div key={index} className="relative">
                    {upload.isUploading && <ProcessingIndicator />}
                    <video
                      muted
                      className="w-full h-full object-cover rounded-xl"
                      src={upload.blobUrl}
                      controls
                    />
                    <input
                      type="hidden"
                      name="media"
                      value={upload.uploadedUrl}
                    />
                    <input type="hidden" name="type" value="video" />
                    {!upload.isUploading && (
                      <button
                        type="button"
                        onClick={() => removeUpload(index)}
                        className="absolute -top-[6px] -right-[6px] bg-pink-300 rounded-full border-2 border-black w-6 h-6 flex items-center justify-center"
                      >
                        <X size={16} />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          <div className="flex gap-3 justify-end items-center">
            <label
              htmlFor="fileInput"
              className={cn("cursor-pointer", {
                "opacity-50": isUploading || !currentUser,
              })}
            >
              <div className="flex items-center gap-2">
                <img className="w-8 h-8" src="/icons/camera.svg" alt="" />
              </div>
            </label>
            <Button
              className={cn("h-8", {
                "opacity-50": !canPost,
                "hover:bg-pink-400": canPost,
              })}
              type="submit"
              disabled={!canPost}
              variant="secondary"
            >
              Post
            </Button>
          </div>

          <input
            disabled={!currentUser}
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept="image/*, video/*"
            onChange={handleFileChange}
          />
        </div>
      </div>
    </Form>
  );
}

export function ProcessingIndicator() {
  return (
    <span
      style={{
        left: "12px",
        top: "12px",
      }}
      className="rounded-xl p-1 bg-primary flex gap-1 items-center absolute text-sm text-black"
    >
      <Loader2 size={16} className="animate-spin" />
      Processing
    </span>
  );
}

interface VideoUrlResponse {
  src?: string;
  error?: string | null;
}

export async function getXVideosUrl(
  tweetId: string,
): Promise<VideoUrlResponse> {
  try {
    const res = await fetch(`/api/xvideourl?id=${tweetId}`);
    const data = await res.json();

    if (res.ok && data.ok) {
      return {
        src: data.src,
        error: null,
      };
    }
    return { error: data.error };
  } catch (error) {
    console.error("Failed to get video URL:", error);
    return { error: "Something went wrong" };
  }
}
